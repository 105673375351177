@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

body {
   background-color: var(--background-color);
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
   min-width: 100vw;
   font-family: 'Inter', sans-serif;
   font-weight: 600;
   letter-spacing: -0.1px;
   padding: 0;
}

.layout {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 45vw;
   min-height: 90vh;
}

.logo-container {
   width: 100%;
   height: 10vh;
   display: flex;
   justify-content: center;
   margin-top: 1rem;
}

.logo-container > svg > path {
   fill: var(--logo-color);
}

.card {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   min-height: calc(100% - 10vh);
   background: var(--card-background-color);
   border: 1px solid var(--border-color);
   box-shadow: 0px 1px 2px var(--box-shadow-color);
   border-radius: 10px;
}

.card-header {
   height: 15vh;
   width: 100%;
   background-color: var(--background-color-light);
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 48px;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
}

.card-body {
   width: 100%;
   margin-bottom: 2rem;
}

.card-footer {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 16px 48px;
   gap: 20%;
   width: 100%;
   height: 10vh;
   background: var(--background-color-light);
}

.btn {
   box-shadow: 0px 1px 2px var(--box-shadow-color) !important;
   border-radius: 6px !important;
   text-transform: capitalize !important;
   display: flex !important;
   flex-direction: row !important;
   justify-content: center !important;
   align-items: center !important;
   gap: 12px !important;
}

.btn.Mui-disabled > svg > path {
   stroke: var(--background-color) !important;
}

@media only screen and (max-width: 1024px) {
   .layout {
      width: 70vw;
   }
}

@media only screen and (max-width: 768px) {
   .layout {
      width: 90vw;
   }
}
